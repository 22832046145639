<template>
  <div>
    <div>
      <label class="font-weight-bolder">{{ $t('Address Type') }}</label>
      <component
        :is="fields[field].type"
        v-for="field in ['billing', 'shipping', 'account_holder']"
        :key="field"
        v-model="address[field]"
        v-bind="getProps(field)"
        :is-editable="isEdit || isCheckingType"
      >
        >
        <!--        <template #loading>-->
        <!--          <b-spinner-->
        <!--            v-if="addressType === field && isCheckingType"-->
        <!--            small-->
        <!--            type="grow"-->
        <!--            class="contacts-checkbox-spinner"-->
        <!--            style="margin-top: 3px; margin-right: 5px"-->
        <!--          />-->
        <!--        </template>-->
      </component>
    </div>
    <div />
    <b-modal
      id="show-contact-types-modal"
      ref="show-contact-types-modal"
      centered
      hide-header
      body-class="show-contact-types-modal__body"
      footer-class="show-contact-types-modal__footer justify-content-between"
      :ok-title="modalOnOKTitle"
      ok-variant="success"
      cancel-variant="outline-primary"
      size="lg"
      @hidden="onHidden"
      @hide="onHide"
      @ok="onOk"
    >
      <div>
        <b-row>
          <b-col cols="12">
            <h3
              v-if="occupiedAddressType"
              class="l-modal__title"
            >
              <span>
                {{ $t(`Proceeding will uncheck the ${contactTypeLabel(addressType)} address on:`) }}
              </span>
            </h3>
          </b-col>
        </b-row>
        <b-list-group>
          <b-list-group-item
            v-for="oct in occupiedAddressType"
            :key="oct.id"
          >
            <b-row class="flex-column">
              <b-col
                cols="12"
                class="d-flex align-items-center"
              >
                <span class="font-weight-bolder">
                  {{ oct.country.name }},
                  {{ oct.state.name }},
                  {{ oct.city }},
                  {{ oct.street }}
                </span>
              </b-col>
              <!--              <b-col-->
              <!--                cols="12"-->
              <!--                class="d-flex"-->
              <!--                style="gap: 10px"-->
              <!--              >-->
              <!--                <span class="font-weight-bolder border-dashed text-success">{{ oct.billing ? "Billing" : '-&#45;&#45;' }}</span>-->
              <!--                <span class="font-weight-bolder border-dashed text-success">{{ oct.account_holder ? "Account Holder" : '-&#45;&#45;' }}</span>-->
              <!--                <span class="font-weight-bolder border-dashed text-success">{{ oct.shipping ? "Shipping" : '-&#45;&#45;' }}</span>-->
              <!--              </b-col>-->
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </div>
      <template #modal-footer="{ ok, cancel}">
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="cancel()"
        >
          <feather-icon
            icon="LCancelIcon"
            size="16"
          />
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="success"
          @click="ok()"
        >
          {{ $t('Proceed') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BModal,
  BSpinner,
  BFormCheckboxGroup,
  BFormGroup,
  BFormCheckbox,
  BListGroup,
  BListGroupItem, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import config, { CONTACT_TYPE_BILLING, CONTACT_TYPE_ACCOUNT_HOLDER, CONTACT_TYPE_SHIPPING } from '../addressConfig'

export default {
  name: 'ContactType',
  components: {
    BButton,
    BRow,
    BCol,
    BModal,
    BSpinner,
    BFormCheckboxGroup,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      checkingType: null,
      isCheckingType: false,
      occupiedAddressType: null,
      addressType: null,
      modalOnOKTitle: 'Keep current changes',
    }
  },
  computed: {
    address() {
      return this.$store.state[this.MODULE_NAME].addressForm
    },
    customer() {
      return this.$store.state[this.CUSTOMER_MODULE_NAME].customer
    },
  },
  methods: {
    contactTypeLabel(data) {
      let label = ''
      switch (data) {
        case CONTACT_TYPE_ACCOUNT_HOLDER: label = 'account holder'
          break
        case CONTACT_TYPE_SHIPPING: label = 'shipping'
          break
        case CONTACT_TYPE_BILLING: label = 'billing'
          break
        default: label = ''
      }
      return label
    },
    showModal(modalName) {
      this.$bvModal.show(modalName)
    },
    hideModal(modalName) {
      this.$bvModal.hide(modalName)
    },
    onChangeHandler(type) {
      // eslint-disable-next-line no-nested-ternary
      this.addressType = type === CONTACT_TYPE_BILLING ? CONTACT_TYPE_BILLING : type === CONTACT_TYPE_ACCOUNT_HOLDER ? CONTACT_TYPE_ACCOUNT_HOLDER : null

      if (this.addressType === undefined || this.addressType === null) return

      this.checkingType = this.addressType
      const customerID = this.customer.id
      const addressID = this.address.id ?? null

      if (!this.address[this.addressType]) return
      this.isCheckingType = true

      if (this.addressType === CONTACT_TYPE_ACCOUNT_HOLDER || this.addressType === CONTACT_TYPE_BILLING) {
        this.modalOnOKTitle = 'Proceed'
      } else {
        this.modalOnOKTitle = 'Proceed'
      }

      this.$store.dispatch(`${this.MODULE_NAME}/checkFreeAddressType`, {
        customer_id: customerID,
        address_id: addressID,
        address_type: this.addressType,
      }).then(res => {
        const { addresses } = res.data

        if (addresses.length) {
          this.occupiedAddressType = addresses
          this.showModal('show-contact-types-modal')
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: i18n.t('Error On Getting Contact Types', {
              module: this.MODULE_NAME,
            }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.checkingType = null
        this.isCheckingType = false
      })
    },
    onHidden() {
      this.onCancelHandler()
    },
    onHide(evt) {
      if (evt.trigger === 'backdrop') {
        this.onCancelHandler()
      }
    },
    onCancelHandler() {
      this.address[this.addressType] = false
    },
    onOk() {
      this.$refs['show-contact-types-modal'].$once('hide', bvEvt => {
        if (bvEvt.trigger === 'ok') {
          this.$refs['show-contact-types-modal'].$once('hidden', () => {
            if (this.addressType === CONTACT_TYPE_ACCOUNT_HOLDER || this.addressType === CONTACT_TYPE_BILLING) {
              this.unCheckAccountHolderFromOtherContact()
            }
            this.address[this.addressType] = true
          })
        }
      })
    },
    unCheckAccountHolderFromOtherContact() {
      const addressID = this.occupiedAddressType[0].id ?? null
      this.isLoading = true
      this.$store.dispatch(`${this.MODULE_NAME}/removeAddressType`, {
        address_id: addressID,
        address_type: this.addressType,
      }).then(res => {
        console.log(res)
      }).catch(() => {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: i18n.t('Error On UnChecking Contact Types', {
              module: this.MODULE_NAME,
            }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.modalOnOKTitle = 'Proceed'
      })
    },
    getProps(fieldName) {
      return {
        is: this.fields[fieldName].type,
        field: this.fields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'address'
    const CUSTOMER_MODULE_NAME = 'customers'

    const { fields } = config()
    return {
      fields,
      MODULE_NAME,
      CUSTOMER_MODULE_NAME,
      CONTACT_TYPE_BILLING,
      CONTACT_TYPE_ACCOUNT_HOLDER,
    }
  },
}
</script>
<style lang="scss">
.contacts-checkbox {

  & .custom-control-label {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    padding-right: 15px;
  }

  & .contacts-checkbox-spinner {
    margin-bottom: 4px;
  }
}
</style>
