<template>
  <div>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="customerAddressCreateVal"
      >
        <b-row>
          <b-col md="6">
            <component
              :is="fields[field].type"
              v-for="field in ['street', 'line2', 'country_id', 'state_id', 'reference']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="address[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col md="6">
            <component
              :is="fields[field].type"
              v-for="field in ['city', 'zip']"
              :key="field"
              v-model="address[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
            <address-type
              :is-edit="isEdit"
            />
            <component
              :is="fields['is_active'].type"
              v-model="address['is_active']"
              v-bind="getProps('is_active')"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({name: 'home-customers-addresses-list'})"
              >
                {{ $t('Back to List') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="secondary"
                class="saveBtn font-medium-1 font-weight-bolder mr-2"
                :disabled="!isFormChanged || isLoading"
                @click="submit( 'home-customers-contacts-create')"
              >
                {{ $t('Save and add Contacts') }}
              </b-button>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged || isLoading"
                @click="submit()"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>

</template>

<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow,
} from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import CustomerName from '../../../components/CustomerName.vue'
import config from '../addressConfig'
import AddressType from './AddressType.vue'

export default {
  name: 'AddressForm',
  components: {
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    CustomerName,
    AddressType,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      required,
      onSubmit: false,
      isLoading: false,
    }
  },
  computed: {
    address() {
      return this.$store.state[this.MODULE_NAME].addressForm
    },
    customerAddressClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].customerAddressForm
    },
    isFormChanged() {
      return JSON.stringify(this.address) !== this.customerAddressClone
    },
  },
  mounted() {
    if (!this.isCreateForm) {
      if (this.$route.query.isEdit) {
        // eslint-disable-next-line no-unused-expressions
        this.$route.query.isEdit === 'true' ? this.$parent.isEditAble() : ''
      }
    }
  },
  methods: {
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit(routerPush = 'home-customers-addresses') {
      this.error = {}
      this.onSubmit = true
      this.$refs.customerAddressCreateVal.validate()
        .then(success => {
          if (success) {
            const data = this.address
            const address = this.mappingFields(Object.keys(this.fields), data, {
              country_id: data.country_id?.id,
              state_id: data.state_id?.id,
              id: data.id,
              billing: data.billing ? 1 : 0,
              shipping: data.shipping ? 1 : 0,
              account_holder: data.account_holder ? 1 : 0,
            })
            this.isLoading = true
            this.sendNotification(this, address, `${this.MODULE_NAME}/${this.address.id ? 'update' : 'create'}`)
              .then(() => {
                this.clear()
                this.$router.push({ name: routerPush })
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
              this.isLoading = false
            })
          } else {
            scrollToError(this, this.$refs.customerAddressCreateVal)
          }
        })
    },
    clear() {
      this.$refs.customerAddressCreateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
    },
    loader() {
      this.$refs.customerAddressCreateVal.reset()
      const smth = JSON.parse(this.customerAddressClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    cancel() {
      this.clear()
      this.$router.push({ name: 'home-customers-addresses' })
    },
    getProps(fieldName) {
      const props = {
        is: this.fields[fieldName].type,
        field: this.fields[fieldName],
        name: fieldName,
      }
      if (fieldName === 'state_id') {
        props.parentValue = this.address.country_id?.id
      }
      return props
    },
  },
  setup() {
    const MODULE_NAME = 'address'
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>
